import React from "react"
import styled from 'styled-components'
import SEO from '../components/seo/SEO'
import Layout from '../layout/layout2'
import { Container, Section, media } from "../utils/utils";

const CenyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;

  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
    `}

   > div {
     > p {
    margin-bottom: .5rem;
    margin-top: 3rem;
    font-weight: 500;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.baseDark};
    }
  }
`;

const H = styled.h5`
  text-align: center;
`;

const Ceny = styled.div`
  display: grid;
  grid-template-columns: auto auto;

 

  li {
    list-style-type: none;
    text-align: right;  
    color: ${({ theme }) => theme.color.baseDark};
  }

  p {
    padding: .3rem 1rem;
    :nth-of-type(odd) {
      background-color: ${({ theme }) => theme.color.baseExtraLight};
    }
  }

  ul {
    padding: .3rem 1rem;
    justify-self: stretch;
    font-weight: 500;

    :nth-of-type(odd) {
      background-color: ${({ theme }) => theme.color.baseExtraLight};
    }
  }
`;

const Cennik = ({ data }) => {

  const strona = data.allDatoCmsCennik.nodes[0]
  const cennik1 = strona.cennikiMakeup
  const cennik2 = strona.cennikiEst
  const cennik3 = strona.cennikiPiel

  return <div>
    <SEO
      title="Cennik"
      slug='/cennik' />
    <Layout>
      <Section pt='4rem'>
        <Container>
          <H>{strona.cennikCategoryName1}</H>
          <CenyWrapper>
            {cennik1.map((item) => {
              return (
                <div key={item.name}>
                  <p>{item.name}</p>
                  <Ceny dangerouslySetInnerHTML={{ __html: item.cenyNode.childMarkdownRemark.html }} />
                </div>
              )
            })}
          </CenyWrapper>
        </Container>
      </Section>

      <Section pt='0'>
        <Container>
          <H>{strona.cennikCategoryName2}</H>
          <CenyWrapper>
            {cennik2.map((item) => {
              return (
                <div key={item.name}>
                  <p>{item.name}</p>
                  <Ceny dangerouslySetInnerHTML={{ __html: item.cenyNode.childMarkdownRemark.html }} />
                </div>
              )
            })}
          </CenyWrapper>
        </Container>
      </Section>

      <Section pt='0'>
        <Container>
          <H>{strona.cennikCategoryName3}</H>
          <CenyWrapper>
            {cennik3.map((item) => {
              return (
                <div key={item.name}>
                  <p>{item.name}</p>
                  <Ceny dangerouslySetInnerHTML={{ __html: item.cenyNode.childMarkdownRemark.html }} />
                </div>
              )
            })}
          </CenyWrapper>
        </Container>
      </Section>

    </Layout>
  </div>

}
export default Cennik

export const query = graphql`
query {
  allDatoCmsCennik {
    nodes {
      heading
      subHeading
      cennikCategoryName1
      cennikCategoryName2
      cennikCategoryName3
      introNode {
        childMarkdownRemark {
          html
        }
      }

      cennikiEst {
        cenyNode {
          childMarkdownRemark {
            html
          }
        }
        name
        rodzajCennika
      }

      cennikiMakeup {
        cenyNode {
          childMarkdownRemark {
            html
          }
        }
        name
        rodzajCennika
      }

      cennikiPiel {
        cenyNode {
          childMarkdownRemark {
            html
          }
        }
        name
        rodzajCennika
      }
    }
  }
}
`